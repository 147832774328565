.floating-container {
  transition: all 0.3s ease;
}

.floating-collapsed {
  width: 69px;
  height: 67px;
  border: transparent;
  opacity: 0.8;
}

.floating-expanded {
  width: 286px;
  height: 80px;
  opacity: 1;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 0.3s;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 0.3s;
}
