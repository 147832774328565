.loading-overlay {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loading-spinner {
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
}

.dot {
  width: 20px;
  height: 20px;
  background-color: #4c51bf; /* Purple color */
  border-radius: 50%;
  animation: bounce 0.6s infinite alternate;
}

.dot:nth-child(2) {
  animation-delay: 0.2s;
}

.dot:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes bounce {
  to {
    transform: translateY(-100%);
  }
}

.loading-text {
  font-size: 1.2rem;
  color: #4c51bf; /* Purple color */
}
