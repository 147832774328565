body {
  min-height: 100vh;
  display: flex;
  #root {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
  }
}

table td {
  vertical-align: top;
}

.insufficient-credits {
  background-color: #8d1100;
}

.rotate-90 {
  --tw-rotate: 90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y))
    rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y))
    scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}
